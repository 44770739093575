//@import url('https://fonts.google.com/specimen/Raleway');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap');

.siraj-header-text {
  float: right;
}

.siraj-header-text-container {
  padding-right: 0 !important;
  min-width: 190px !important;
  width: min-content !important;
}

.siraj-header-text-container > div {
  padding-top: 10px !important;
  padding-bottom: 1px !important;
}

.siraj-logo-container {
  background: #fffffff2;
  height: 10vh !important;
  border: 2px solid #d82c32;
}

.siraj-logo-container > img {
  top: 0 !important;
  bottom: 0 !important;
  left: 2px !important;
  right: 2px !important;
  margin: auto !important;
  position: absolute !important;
}

.siraj-features-section {
  margin-left: 9vw;
  margin-right: 9vw;
}

.siraj-long-text {
  line-height: 28px;
}

.siraj-footer {
  margin-left: 7vw;
  margin-right: 7vw;
}

.text-center {
  text-align: center !important;
}

.landing-font {
  font-family: 'Noto Sans', sans-serif !important;
}

h4.landing-font {
  font-weight: 400;
  color: #353535;
}

h4.landing-font-light {
  font-weight: 400;
  color: #6f6565;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0;
}

.red-background {
  background: #d50000eb;
}

.max-height-header {
  height: 10vh !important;
}

.red-text {
  color: #D50000;
}

.header-row {
  width: 100%;
  z-index: 999;
  height: 10vh !important;
  padding-top: 0 !important;
  position: fixed !important;
  margin-top: -1px !important;
  padding-bottom: 0 !important;
}

.siraj-landing-cards {
  max-width: 260px !important;
  border-radius: 25px !important;
  border: 1px solid red !important;
}

.siraj-landing-cards-min-height {
  max-width: 260px !important;
  min-height: 170px !important;
  border-radius: 25px !important;
  border: 1px solid red !important;
}

.siraj-landing-cards-min {
  max-height: 140px;
  display: table !important;
  max-width: 160px !important;
  min-height: 140px !important;
  border-radius: 25px !important;
  border: 1px solid red !important;
}

.siraj-landing-cards-min > .content {
  display: table-cell !important;
  vertical-align: middle !important;
}

.siraj-card-group {
  margin-left: 1px !important;
  margin-right: 1px !important;
  width: 80vw;
}

.landing-cards > img {
  margin: auto !important;
}

.text-white {
  color: white;
}

.float-right {
  float: right !important;
}

.siraj-about-section {
  border-radius: 25px;
  border: 1px red solid !important;
}

.centered-image {
  margin: auto !important;
}

.pointer {
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.siraj-logo {
  max-height: 10vh;
  width: auto !important;
}

.active-link {
  text-decoration: underline;
}

.business-images {
  width: 100px;
  height: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.business-card {
  height: 200px;
  text-align: center !important;
}

.siraj-section-title-first {
  color: #ff8d00;
  font-size: 24px;
  font-weight: bold !important;
  margin-top: -10px !important;
}

.siraj-section-title {
  color: #ff8d00;
  font-size: 24px;
  margin-top: 20px !important;
  font-weight: bold !important;
}

.section-text-color {
  color: #ff8d00;
}

.siraj-quote-text {
  font-size: 1.2em;
  line-height: 28px;
  font-style: italic;
}

.siraj-section-separator {
  line-height: 0;
  font-size: 18px;
  margin-top: -10px;
  letter-spacing: 2px;
  color: #bdbbbb !important;
}

.siraj-stats-text {
  color: #4e4e4e !important;
}

.siraj-stats-description {
  color: #4e4e4e !important;
}

.statistics {
  height: 150px !important;
  text-align: center !important;

  h1 {
    color: #ff8d00;
  }
}

.thanks-section {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.support-button {
  border-radius: 50px !important;
}

.footer {
  padding-top: 15px;
  padding-bottom: 15px;
}


.siraj-landing-header {
  min-height: 75vh;
  background-size: cover;
  margin-bottom: 20px !important;
  background: url(https://nef-eflow-static.s3-us-west-2.amazonaws.com/assets/landing/lebanon_prm_profile_hanan_0508.jpg) no-repeat center;
  background-size: cover;
}

.siraj-landing-header > .content-wrapper {
  padding: 0;
  background-color: #ffffff21;
}

.siraj-landing-header > .content-wrapper > h1 {
  color: white;
  font-size: 5em;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.siraj-landing-header > .content-wrapper > p {
  color: #FFECE8;
  max-width: 630px;
  font-size: 1.7em;
  line-height: 1.5em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}


.cherpa-action-button {
  color: white !important;
  background-color: #fd3b23 !important;
}

.siraj-first-section {
  height: 25vh;
}

@media (max-width: 767.98px) {
  .siraj-landing-header > .content-wrapper > h1 {
    font-size: 3em;
    max-width: 400px;
  }

  .siraj-landing-header > .content-wrapper > p {
    font-size: 1.5em;
  }
}

.ar{
  direction: rtl;
  font-family: 'Almarai' !important;
}

.ar-right{
  direction: rtl;
  font-family: 'Almarai' !important;
  text-align: right;
}