.page-text {
  font-family: 'Tajawal', sans-serif !important;
}

.header-title-1 {
  color: white !important;
  font-size: 2.5rem;
}

.header-title-2 {
  color: #51514E !important;
  font-size: 2.5rem;
}

.header-title-3 {
  color: #0b4a56 !important;
  font-size: 2.5rem;
}

.card-description {
  color: #51514E !important;
  font-size: 1.5em;
  line-height: 26px;
}

.card-description-idea {
  color: #51514E !important;
  font-size: 16px;
  line-height: 26px;
  width: unset !important;
}

.card-description-username {
  width: unset !important;
  font-size: 14px !important;
}

.card-image {
  max-width: 100%;
  margin: auto;
  height: auto;
  border-radius: 25px !important;
}

.card-image>img {
  border-radius: 25px !important;
  width: 90% !important;
  margin: auto !important;
  padding: 10px !important;
  max-height: 290px;
  /*
  -moz-box-shadow: 1px 1px 3px #ccc;
  -webkit-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  */
}

.ui.card> :first-child,
.ui.cards>.card> :first-child {
  border-radius: 25px !important;
  padding: 0;
}

.sharing-card>.image {
  background-color: white !important;
}

.card-background {
  background-color: transparent !important;
}

.sharing-card>.content {
  /*border: none !important;*/
}

.sharing-card>.content>.header {
  color: #D70085 !important;
  font-size: xx-large !important;
}

.sharing-card>.content>.header.font-16 {
  color: #D70085 !important;
  font-size: 16px !important;
}

.sharing-card>.extra,
.ui.card>.extra,
.ui.cards>.card>.extra {
  border: none !important;
}

.sharing-segment {
  max-height: 100%;
  border-width: 0 !important;
  background: #75C0AF !important;
  overflow: scroll;
  border: 0 !important;
  margin: 0;
  height: 100%;
}

.sharing-segment-hidden-users {
  max-height: 100%;
  border-width: 0 !important;
  background: #75C0AF;
  overflow: scroll;
  margin: 0 !important;
  border: 0 !important;
  height: 100%;
  border: 0 !important;
}

.sharing-inner-segment,
.sharing-inner-segment-force,
#sharing-inner-segment-force {
  border: 1px solid #009cad47 !important;
  padding-top: 25px;
  border-width: 0px !important;
  overflow-y: auto;
  overflow-x: hidden;
  background: #75C0AF;
  height: 66vh;
  /*border: 0px solid #e6efff00 !important;*/
}

.sharing-card {
  border-radius: 25px !important;
  -moz-box-shadow: 3px 3px 5px #969595a1 !important;
  -webkit-box-shadow: 3px 3px 5px #969595a1 !important;
  box-shadow: 3px 3px 5px #969595a1 !important;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
}

.align-left {
  text-align: left !important;
  font-size: 20px !important;
  padding: 10px !important;
}

.no-margin {
  margin: 0 !important;
}

.position-bottom {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}