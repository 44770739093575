@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

html,
body,
#root {
    height: 100%
}

.fullscreen {
    height: 100%;
}

/*
:root {
    --lightGrey: #ffffff80;
    --darkBlue: #177CBD;
    --majorWidth: 920px;
    --sidebar-border: #1b30701c;
    
    --primary-color: #97a3aa;
    --highlight-color:#1b306f47;
    --selected-color:#1b306f1f;
    --primary-border-color:#177CBD;
    --background-color: ##f1f4f5;
    
    --navbar-background-color: #273238;
    --sidebar-background-color: #273238; 
}
*/
:root {
    --lightGrey: #ffffff80;
    --darkBlue: #177CBD;
    --majorWidth: 920px;
    --sidebar-border: #1b30701c; /*#f74e0029*/

    --primary-color: #97a3aa; /* #f26524;*/
    --highlight-color: #1b306f1f; /*#ffdcc0;*/
    --selected-color: #1b306f1f; /*#ffdcc0;*/
    --primary-border-color: #177CBD; /*#f26524;*/
    --background-color: #f1f4f5;

    --navbar-background-color: #283a46;
    --sidebar-background-color: #273238;
}

* {
    font-family: 'Noto Sans', sans-serif;
}

p {
    margin: 0;
    color: #4a5669 !important;
}

.ar {
    direction: rtl;
}

.parentNefContainer {
    display: flex;
    min-height: 100%;
    position: relative;
    flex-direction: column;
    background-color: #21314af7;
}

/* buttons */

.filter-submit-button {
    color: white !important;
    font-weight: 500 !important;
    width: 100% !important;
    background-color: #273238 /*#f2711c*/ !important;
    box-shadow: 0 0 0 1px var(--primary-color) inset !important;
}
.secondary-filter-submit-button {
    color: #273238 !important;
    font-weight: 500 !important;
    width: 100% !important;
    background-color: white /*#f2711c*/ !important;
    box-shadow: 0 0 0 1px var(--primary-color) inset !important;
}
.primary-button {
    color: white !important;
    font-weight: 500 !important;
    background-color: #273238 /*#f2711c*/ !important;
    box-shadow: 0 0 0 1px var(--primary-color) inset !important;
}

.primary-button-light {
    color: white !important;
    font-weight: 500 !important;
    box-shadow: 0 0 0 1px #cacaca0d inset !important;
    background-color: #cacaca0d /*#f2711c*/ !important;
}

.primary-button-light > div {
    color: white !important;
}

.secondary-button {
    color: #273238 !important;
    font-weight: 500 !important;
    background-color: #f7f7f7 !important;
    box-shadow: 0 0 0 1px #e0e0e0 inset !important;
}

/* headers */

.ui.header {
    font-weight: 600 !important;
}

.dimmer {
    z-index: 1 !important;
}

/* Segments */
.segment-container {
    /*overflow-y: scroll;*/
    height: 89vh !important;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0);
    margin: 0px !important; /*TOCHECK*/
}

.segment-container-mobile {
    height: 100vh !important;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0);
}

/* Grid margin */
.page-grid {
    height: 92vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 0 !important;
    margin-left: 0 !important;
    background: #f1f4f5 !important;
    /*padding-top: 1rem !important;*/
}

/* Tab */
.ui.tabular.menu {
    border-bottom: none !important;
}

.ui.segment {
    border: 1px solid #e6efff !important;
}

.list-selected {
    color: rgba(0, 0, 0, .8) !important;
    background: rgba(0, 0, 0, .03) !important;
}

.react-draggable {
    border-radius: 30px !important;
}
