.w-100 {
    width: 100% !important;
}

.freeze-head-and-col {
    height: calc(100vh - 350px);
    overflow-y: auto !important;
    display: block !important;
    width: 900px !important;
    margin: auto !important;

    @media (max-width: 768px) {
        height: calc(100vh - 400px);
        margin: 0 !important;
        width: 100% !important;
    }
}

/*Fix the header to the top*/
.freeze-head-and-col tr:first-of-type th {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    width: 450px !important;
}

/*Fix the first column  to the left*/
.freeze-head-and-col td:first-of-type {
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    font-weight: bold;
}

/* Edit: corner fixed always */
.freeze-head-and-col tr:first-of-type th:first-of-type {
    z-index: 100;
    left: 0;
}

.page-text {
    width: 451px;
}

.continue-btn {
    color: #454343 !important;
    text-shadow: none;
    background-image: none;
}

.ui.striped.table tbody tr:nth-child(2n),
.ui.striped.table>tr:nth-child(2n) {
    background-color: rgb(116 192 176 / 13%)
}